<template>
  <v-checkbox
    dense
    @change="toggleSelected"
    v-model="selected"
    color="primary"
    :disabled="disabledSelect"
    :messages="disabledSelect ? '.' : ''"
  >
    <template v-slot:message v-if="disabledSelect">
      <div class="pt-1" style="margin-left: 6px">
        <i class="fa fa-ban red--text"></i>
      </div>
    </template>
  </v-checkbox>
</template>

<script lang="babel" type="text/babel">
import listRow from '@/components/list/mixins/listRow'
export default {
  mixins: [listRow],
}
</script>

<style lang="sass" type="text/sass" scoped></style>
